$bg-color-a: #fff;
$bg-color-b: #f7f7f7;
$bg-color-c: #f0f0f0;
$bg-color-d: #e8e8e8;

$hover-bg: #f5f5f5;

$primary-color: #513a98;
$primary-color-raised: darken(#513a98, 8%);
$primary-color-contrast: #fff;

$dark-color: #293f53;
$dark-color-raised: darken($dark-color, 8%);
$dark-color-contrast: #fff;

$text-darkest: #000;
$text-dark: $dark-color;
$text-light: #999;

$error-color: #f44336;

$input-text-color: #495057;
$input-bg: #fff;
$border-1: 1px solid #ced4da;

$main-box-shadow: 0px 0px 45px 0px rgb(74 85 174 / 20%);

@mixin page-host {
    height: 100%;
    display: block;
    overflow-y: auto;
    padding: 64px 16px 0;
}

$sg-theme-primary: (
    50 : #eae7f3,
    100 : #cbc4e0,
    200 : #a89dcc,
    300 : #8575b7,
    400 : #6b58a7,
    500 : #513a98,
    600 : #4a3490,
    700 : #402c85,
    800 : #37257b,
    900 : #27186a,
    A100 : #b2a4ff,
    A200 : #8771ff,
    A400 : #5c3eff,
    A700 : #4725ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$sg-theme-secondary: (
    50 : #e5e8ea,
    100 : #bfc5cb,
    200 : #949fa9,
    300 : #697987,
    400 : #495c6d,
    500 : #293f53,
    600 : #24394c,
    700 : #1f3142,
    800 : #192939,
    900 : #0f1b29,
    A100 : #68a9ff,
    A200 : #358cff,
    A400 : #026fff,
    A700 : #0063e8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);