@use '@angular/material' as mat;
@import "src/assets/icons/style.scss";
@import "src/styles/all.scss";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$softygon-primary: mat.define-palette($sg-theme-primary);
$softygon-accent: mat.define-palette($sg-theme-secondary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$softygon-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$softygon-theme: mat.define-light-theme($softygon-primary, $softygon-accent, $softygon-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($softygon-theme);


.fa {
    font-size: 21px;
}

input, textarea {
    border: $border-1;
    border-radius: 4px;
    padding: 8px 16px;
    box-shadow: 0 0 8px $bg-color-b inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $input-bg  inset !important;
}

body {
    background-color: $bg-color-d;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.8),
    rgba(255,255,255,0.6)), url('/assets/sftgnbg.webp');
    background-size: cover;
}

.sg-btn, button.sg-btn {
    border: none;
    background: $primary-color;
    color: $primary-color-contrast;
    border-radius: 4px;
    padding: 10px 16px;
    box-sizing: border-box;
    height: 39px;
    cursor: pointer;
    text-align: center;
    transition: opacity 0.5s;
    font-weight: 500;
    text-transform: uppercase;

    &:hover:not(.disabled) {
        background: $primary-color-raised;
    }
    &.base-width {
        min-width: 120px;
    }
    &.disabled {
        opacity: 0.33;
        cursor: default;
        transition: opacity 0.1s;
    }
    &.sg-secondary {
        background: $primary-color-contrast;
        color: $primary-color;
        border: $primary-color solid 1px;
        &:hover:not(.disabled) {
            background: $hover-bg;
        }
    }

    &.small {
        height: 30px;
        line-height: 32px;
        padding: 0 10px;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
    outline: none;
}

mat-icon.mat-icon {
    font-size: 14px;
    height: 16px;
    width: 16px;
    vertical-align: middle;
}

.sg-input {
    height: 39px;
    width: 100%;
    outline: none;
    display: block;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    padding: 4px 8px;
    border: $border-1;
    border-radius: 4px;
    font-size: 16px;
    color: $input-text-color;
}

.p-dropdown {
    height: 39px;
    min-width: 120px;
    margin: 0 0 10px 0;
    border-radius: 4px;
}

.sg-textarea {
    color: $input-text-color;
    width: 100%;
    outline: none;
    display: block;
    margin: 8px 0;
    box-sizing: border-box;
}

.small-width {
    max-width: 80px;
}

.normal-width {
    max-width: 320px;
}

.full-width {
    width: 100%;
}

.sg-link, a {
    cursor: pointer;
    text-decoration: none;
    color: $primary-color;
    &:hover {
        opacity: 0.75;
    }
    &.secondary {
        color: $dark-color;
    }
}

.page-container {
    background-color: $bg-color-a;
    padding: 0 16px 16px 16px;
    border-radius: 4px;
    height: calc(100% - 16px);
    max-height: calc(100% - 16px);
    box-sizing: border-box;
    overflow: auto;
    box-shadow: $main-box-shadow;

    .header {
        padding-top: 16px;
        margin-bottom: 16px;
        border-bottom: 2px solid $bg-color-d;
        color: $dark-color;
        position: sticky;
        top: 0;
        background-color: $bg-color-a;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .title-row {
        display: flex;
        min-height: 39px;
    }

    .title {
        font-size: 20px;
        flex: 1;
        padding: 12px 0 20px 0;
    }
}

.line {
    width: 100%;
    height: 0;
    border-bottom: 2px solid $bg-color-d;

    &.spaced {
        margin: 16px 0;
    }
}

.on-public-lang {
    display: block;
    text-align: center;
    margin: 20px auto;
    max-width: 60px;
}


/* SPINNER */
.spinner {
    width: 40px;
    height: 40px;
    background-color: $primary-color;

    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
    0% { 
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    } 50% { 
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
    } 100% { 
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}


.auth-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .links {
        margin: 16px auto 8px auto;
        text-align: center;
    }

    .feedback {
        padding: 16px 0;
        font-size: 18px;
        text-align: center;
    }

    .claim {
        padding: 16px 0;
        font-size: 14px;
    }
}

.auth-content {
    width: 320px;
    max-width: 100%;
}

.auth-form {
    background-color: $bg-color-a;
    padding: 16px;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    box-shadow: $main-box-shadow;
    
    app-pseudo-logo {
        display: block;
        padding-bottom: 32px;
    }
}

.base-info {
    display: flex;
    margin: 16px 0;
    padding: 16px;
    border: $border-1;
    .col {
        display: flex;
        flex: 1 0 200px;
        flex-direction: column;
    }
}

.btns-row {
    display: flex;
    & > * {
        margin-left: 8px;
    }
}

.filters-row {
    display: flex;
    gap: 12px;
  }
  
  p-calendar {
    min-width: 110px;
  }

.icon-spinner {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }